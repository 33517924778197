<template>
  <div>
    <div class="text-header mb-10">Subscribed Successfully, Thank you!</div>
    <PlanCard :product="getChosenPlan" :showCTAButton="false" />
    <v-btn
      class="my-4 white--text"
      rounded
      color="#00b08d"
      large
      block
      @click="$router.push({ name: 'CustomerDashboard' })"
      >Go My Dashboard</v-btn
    >
  </div>
</template>

<script>
import PlanCard from "@/components/choose-plan/PlanCard.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  created() {
    // set the app bar title
    this.setCurrentAppBarTitle("Payment");
  },
  name: "PaymentSuccess",
  components: {
    PlanCard,
  },
  methods: {
    ...mapMutations(["setCurrentAppBarTitle"]),
  },
  computed: {
    ...mapGetters(["getChosenPlan"]),
  },
};
</script>

<style scoped>
</style>